import React from 'react';
import {Badge} from '../../Atoms/Badge'
import * as styles from './SectionHeader.module.scss'

function SectionHeader({h2, children, bigText, className}) {
    return (
        <div className={styles.sectionHeader + " " + className}>
            <p className={styles.bigText}>{bigText}</p>
            <Badge h2={h2} className={styles.badge}>{children}</Badge>

        </div>
    );
}

export default SectionHeader;