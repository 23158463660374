import React from 'react';
import Badge from "./Badge";
import * as styles from './BadgeBig.module.scss'

function BadgeBig(props) {
    return (
        <Badge {...props} className={`${styles.BadgeBig} ${props.className}`}>{props.children}</Badge>
    );
}

export default BadgeBig;