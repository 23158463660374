import React from 'react';
import * as styles from './Badge.module.scss';


function Badge(props) {
    const Layout = (props) => props.h2 ? <h2 {...props}>{props.children}</h2> : <span {...props}>{props.children}</span>;
    return (

        <Layout {...props} className={`${styles.badge} ${props.className}`}/>

    );
};

export default Badge;